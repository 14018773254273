import React, { useState } from 'react'
import { AlertCircle } from 'react-feather'
import { Tooltip } from 'reactstrap'
import HelpSvg from '../common_svg/HelpSvg'

export default function CustomeTooltip({ id, label, fieldName, alert, className }) {
    const [tooltipOpen, setTooltipOpen] = useState(false)

    return (
        <span className={`m-0 cursor-pointer ${className}`} id={`custome-${fieldName}-${id}`}>
            <Tooltip
                placement='top'
                isOpen={tooltipOpen}
                target={`custome-${fieldName}-${id}`}
                toggle={() => setTooltipOpen(!tooltipOpen)}
                className='tooltip-label'
            >
                <div dangerouslySetInnerHTML={{ __html: label }}></div>
            </Tooltip>
            {alert ? <AlertCircle size={20} color={'#EA5455'} className='munim-ml-6' /> : <HelpSvg svg_width={16} svg_height={16} />}
        </span>
    )
}
