/* eslint-disable no-useless-return */
import React, { } from 'react'
import { Book, BookOpen, Circle, Clipboard, File, FilePlus, FileText, Home, MessageSquare, PieChart, Settings, ShoppingBag, Tag } from "react-feather"
import { exportFileApi, sampleFileApi } from "./commonApi"
import { GetApiCall } from "./axios"
import axios from 'axios'
import stateOfIndia from '../common_components/stateOfIndia.json'
import CommonApiEndPoint from './commonApiEndPoint'
import { notifyMessage } from '../common_components/Validation'
import CommonRouter from './commonRoute'
import { InputGroup } from 'reactstrap'
import InputNumberField from '../common_components/custom_field/InputNumberField'
import { Tooltip } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import printJS from 'print-js-updated'
import CheckRoundBlueSvg from '../common_components/common_svg/CheckRoundBlueSvg'
import EwayGeneratedSvg from '../common_components/common_svg/E-wayGeneratedSvg'
import EInvoiceGeneratedSvg from '../common_components/common_svg/E-InvoiceGeneratedSvg'
import PaperEditSvg from '../common_components/common_svg/PaperEditSvg'
import BankSvg from '../common_components/common_svg/BankSvg'
import PaymentSvg from '../common_components/common_svg/PaymentSvg'
import ReceiptSvg from '../common_components/common_svg/ReceiptSvg'
import GSTSvg from '../common_components/common_svg/GSTSvg'
import RepeatSvg from '../common_components/common_svg/RepeatSvg'
import StockClipBoardSvg from '../common_components/common_svg/StockClipBoardSvg'
import BarcodeSvg from '../common_components/common_svg/BarcodeSvg'
import ContraSvg from '../common_components/common_svg/ContraSvg'

/* eslint-disable no-var */
const convertToBoolean = (binary_value) => {
   if (binary_value === 0 || binary_value === '0') {
      return false
   } else {
      return true
   }
}

const convertToBinary = (boolean) => {
   if (boolean === true) {
      return 1
   } else {
      return 0
   }
}

function checkStrExistInArray(str, array) {
   for (let i = 0; i < array.length; i++) {
      if (array[i].includes(str)) {
         return true
      } else {
         return false
      }
   }
}
/**
 * IW0077
 * This function is called detect os.
 */
function getDetectOs(navigator) {
   if (navigator === 'MacIntel') {
      return 'OPTION'
   } else {
      return 'ALT'
   }
}
/**
  * IW0118
  * This function is call on if focus on save or save before active element and press tab to focus move on first active field.
  */
function handleFocusTab(e, nextFocusId) {
   if (e.keyCode === 9 && nextFocusId && !e.shiftKey) {
      e?.preventDefault()
      e.stopPropagation()
      window.stop()
      document.getElementById(nextFocusId)?.focus()
   }
}
/**
 * IW0077
 * This function is called when user get cookies
 */
function getCookie(key) {
   var match = document.cookie.match(new RegExp(`(^| )${key}=([^;]+)`))
   if (match) return match[2]
}

const setCookie = (name, value, excookie) => {
   const systemDate = new Date()
   systemDate.setTime(systemDate.getTime() + (excookie * 60 * 1000))
   const expires = `expires=${systemDate.toUTCString()}`
   document.cookie = `${name}=${value};${expires};path=/; SameSite=None; Secure`
}

function getJsDate(date, format = 'DD-MM-YYYY') {
   const splitDate = date.split('-')
   switch (format) {
      case 'DD-MM-YYYY':
         return new Date(splitDate[2], Number(splitDate[1]) - 1, splitDate[0])
      case 'MM-DD-YYYY':
         return new Date(splitDate[2], Number(splitDate[0]) - 1, splitDate[1])
      case 'YYYY-MM-DD':
         return new Date(splitDate[0], Number(splitDate[1]) - 1, splitDate[2])
      default:
         break
   }
   return new Date()
}
function changeDateSeparator(date = '', existing_separator, replace_separator) {
   return date.replaceAll(existing_separator, replace_separator)
}

function getTime(date) {
   return new Date(date).getTime()
}

function getJsTime(jsDate) {
   return (jsDate).getTime()
}
function arrayToObject(data, index_key = null) {
   const newArray = {}
   if (data && data.length) {
      data.map((item) => {
         newArray[item[index_key]] = item
      })
   }
   return newArray
}
function handlePageRefresh(event) {
   const e = event || window.event
   // Cancel the event
   e.preventDefault()
   if (e) {
      e.returnValue = '' // Legacy method for cross browser support
   }
   return '' // Legacy method for cross browser support
}
function debounce(fn, time) {
   let timer
   return function (...args) {
      clearTimeout(timer)
      timer = setTimeout(() => {
         fn(...args)
      }, time)
   }
}
const getIcon = (str, size) => {
   switch (str) {
      case 'Home':
         return <Home size={size} />
      case 'FilePlus':
         return <FilePlus size={size} />
      case 'FileText':
         return <FileText size={size} />
      case 'Tag':
         return <Tag size={size} />
      case 'Settings':
         return <Settings size={size} />
      case 'Repeat':
         return <RepeatSvg />
      case 'Circle':
         return <Circle size={size} />
      case 'Book':
         return <Book size={size} />
      case 'BookOpen':
         return <BookOpen size={size} />
      case 'Clipboard':
         return <Clipboard size={size} />
      case 'File':
         return <File size={size} />
      case 'Chat':
         return <MessageSquare size={size} />
      case 'PieChart':
         return <PieChart size={size} />
      case 'ShoppingBag':
         return <ShoppingBag size={size} />
      case 'Truck':
         return <EwayGeneratedSvg svg_width={20} svg_height={20} />
      case 'StockClipboard':
         return <StockClipBoardSvg />
      case 'GSTFile':
         return <GSTSvg />
      case 'Einvoice':
         return <EInvoiceGeneratedSvg svg_width={20} svg_height={20} />
      case 'BlutTick':
         return <CheckRoundBlueSvg />
      case 'FeatureRequest':
         return <PaperEditSvg />
      case 'Bank':
         return <BankSvg />
      case 'Payment':
         return <PaymentSvg />
      case 'Receipt':
         return <ReceiptSvg />
      case 'Barcode':
         return <BarcodeSvg />
      case 'Contra':
         return <ContraSvg />
      default:
         break
   }
}
const detectBrowser = () => {
   const nAgt = navigator.userAgent
   let browserName = navigator.appName
   let nameOffset, verOffset
   // In Opera, the true version is after "Opera" or after "Version"
   if ((verOffset = nAgt.indexOf("OPR")) !== -1) {
      browserName = "Opera"
   } else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
      // In MSIE, the true version is after "MSIE" in userAgent
      browserName = "Microsoft Internet Explorer"
   } else if ((verOffset = nAgt.indexOf("Edg")) !== -1) {
      browserName = "Edge"
   } else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
      // In Chrome, the true version is after "Chrome"
      browserName = "Chrome"
   } else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
      // In Safari, the true version is after "Safari" or after "Version"
      browserName = "Safari"
   } else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
      // In Firefox, the true version is after "Firefox"
      browserName = "Firefox"
   } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
      // In most other browsers, "name/version" is at the end of userAgent
      browserName = nAgt.substring(nameOffset, verOffset)
   }
   return browserName
}
const checkDesktopApp = () => {
   if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
      return `width=${window.outerWidth},height=${window.outerHeight}`
   } else {
      return ''
   }
}
async function getExportData(export_type, action, path, setOverlayLoading, notify, setExportData, export_download = false, history, mobile_no, is_direct_print = 0) {
   if (((export_type === 'xlsx') || (export_type === 'pdf'))) {
      setOverlayLoading(true)
   }
   const header = { 'access-token': localStorage.getItem('access_token') }
   const res = await GetApiCall('GET', path, header)
   if (res.data.status === 'success') {
      setOverlayLoading(false)
      const download_link = `${exportFileApi}${res.data.data}`
      if ((export_type === 'xlsx' && action === '1') || (export_type === 'pdf' && action === '1')) {
         if (export_type !== 'pdf' && detectBrowser() === 'Edge') {
            location.href = download_link
         } else {
            if (is_direct_print === 1) {
               const is_desktop = checkDesktopApp()
               if (is_desktop) {
                  document.getElementById('pdf-print').setAttribute('d-link', download_link)
                  document.getElementById('pdf-print').click()
               } else {
                  if (detectBrowser() === 'Safari') {
                     printJS(download_link)
                  } else {
                     if (export_download) {
                        printJS(download_link)
                     } else {
                        printJS(download_link)
                     }
                  }
               }
            } else {
               if (detectBrowser() === 'Safari') {
                  window.open(download_link, '_self', 'noopener,noreferrer')
               } else {
                  if (export_download) {
                     window.open(download_link, '_self', 'noopener,noreferrer')
                  } else {
                     window.open(download_link, '_blank', 'noopener,noreferrer')
                  }
               }
            }
         }
         if (notify) {
            notify(res.data.message, 'success')
         }
         if (setExportData) {
            setExportData(false)
         }
      } else if (((export_type === 'pdf' && action === '2') || (export_type === 'xlsx' && action === '2')) && notify) {
         if (res.data.message?.includes('Click')) {
            notify(res.data.message, 'success', () => history.push(CommonRouter.email_preference))
         } else {
            notify(res.data.message, 'success')
         }
      } else if (((export_type === 'pdf' && action === '3') || (export_type === 'xlsx' && action === '3')) && notify) {
         if (mobile_no) {
            window.open(`https://web.whatsapp.com/send?phone=${mobile_no}&text="hello"&attachment=${sampleFileApi}${(res.data.data)}`)
            notify(res.data.message, 'success')
         } else {
            notify(notifyMessage.whatsapp_contact_not_add, 'error')
         }
      }
   } else {
      setOverlayLoading(false)
      if (notify) {
         notify(res.data.message, 'error')
      }
   }
   return
}
/**
 * IW0077
 * This function is called when user get user ip address
 */
const getUserIpAddress = async () => {
   if (localStorage.getItem('user_ip')) {
      return localStorage.getItem('user_ip')
   } else {
      const user_ip = await axios.get('https://api.bigdatacloud.net/data/client-ip').then(response => { return response.data.ipString }).catch(() => '')
      localStorage.setItem('user_ip', user_ip)
      return user_ip
   }
}
/**
 * IW0110
 * This function is called when user get state data
 */
const getStateData = async (country_id) => {
   if (country_id === 101) {
      return stateOfIndia
   } else {
      if (country_id) {
         const header = { 'access-token': localStorage.getItem('access_token') }
         const res = await GetApiCall('GET', `${CommonApiEndPoint.get_states}?id=${country_id}`, header)
         if (res.data.status === 'success' && res.data.statusCode === 200) {
            return res.data.data
         }
      }
   }
}
/**
 * IW0079
 * function is used to add manual event on any element of body
 */
function munimAddCustomEventListener(selector, event, handler) {
   const rootElement = document.querySelector('body')
   rootElement.addEventListener(event, function (evt) {
      var targetElement = evt.target
      // eslint-disable-next-line eqeqeq
      while (targetElement != null) {
         if (targetElement.matches(selector)) {
            handler(evt)
            return
         }
         targetElement = targetElement.parentElement
      }
   },
      true
   )
}
/**
 * IW0110
 * This function is called when user get city data
 */
const getCityData = async (state_id, company_id = '') => {
   const header = { 'access-token': localStorage.getItem('access_token') }
   const res = await GetApiCall('GET', `${CommonApiEndPoint.get_cities}?id=${state_id}&company_id=${company_id}`, header)
   if (res.data.status === 'success' && res.data.statusCode === 200) {
      return res.data.data
   }
}
/**
 * IW0156
 * This function is called when user get Mfg. company list
 */
const getMfgCompanyList = async (company_id) => {
   const header = { 'access-token': localStorage.getItem('access_token') }
   const res = await GetApiCall('GET', `${CommonApiEndPoint.mfg_company_filter}?id=${company_id}`, header)
   if (res.data.status === 'success' && res.data.statusCode === 200) {
      return res.data.data
   }
}

/**
* IW0125
 * This function is called when the Owner/Manager/Staff  trial & subscription plan expire
 */
const planExpireCheck = (notify, is_active, subs_type, roles, isCreateModule = true) => {
   if ((isCreateModule) && is_active === '0' && subs_type === '0') {
      notify(notifyMessage[`trial_expire_${roles}`], 'error')
   } else if ((isCreateModule) && is_active === '0') {
      notify(notifyMessage[`subscription_expire_${roles}`], 'error')
   } else {
      return true
   }
}

const isIosDevice = () => {
   const myParam = detectBrowser()
   if (myParam === 'Safari') {
      return true
   } else {
      return false
   }
}

const getOS = () => {
   const userAgent = window.navigator.userAgent,
      platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
      macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod']
   let os = null

   if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS'
   } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS'
   } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows'
   } else if (/Android/.test(userAgent)) {
      os = 'Android'
   } else if (/Linux/.test(platform)) {
      os = 'Linux'
   }
   return os
}
/**
 * IW0156
 * This function is call for Create mode
 */
const isCreateMode = () => {
   if (window?.location?.pathname?.includes('create')) {
      return true
   } else {
      return false
   }
}
/**
 * IW0156
 * This function is call for Edit mode
 */
const isEditMode = () => {
   if (window?.location?.pathname?.includes('edit')) {
      return true
   } else {
      return false
   }
}
/**
 * IW0156
 * This function is call for View mode
 */
const isViewMode = () => {
   if (window?.location?.pathname?.includes('view')) {
      return true
   } else {
      return false
   }
}
/**
 * IW0156
 * This function is call for Default View mode
 */
const isDefaultViewMode = () => {
   if (window?.location?.pathname?.includes('default-view')) {
      return true
   } else {
      return false
   }
}
/**
 * IW0156
 * This function is call for Default Edit mode
 */
const isDefaultEditMode = () => {
   if (window?.location?.pathname?.includes('default-edit')) {
      return true
   } else {
      return false
   }
}
/**
 * IW0156
 * This function is call for Sales mode
 */
const isSalesMode = () => {
   if (window?.location?.pathname?.includes('sales')) {
      return true
   } else {
      return false
   }
}
/**
 * IW0156
 * This function is call for Purchase mode
 */
const isPurchaseMode = () => {
   if (window?.location?.pathname?.includes('purchase')) {
      return true
   } else {
      return false
   }
}
/**
 * IW0156
 * This function is call for Common Router mode
 */
const isRouterMode = (route) => {
   if (window?.location?.pathname?.includes(route)) {
      return true
   } else {
      return false
   }
}

export { convertToBoolean, convertToBinary, checkStrExistInArray, getCookie, getJsDate, getTime, getJsTime, getDetectOs, handlePageRefresh, changeDateSeparator, debounce, getIcon, detectBrowser, getExportData, arrayToObject, getUserIpAddress, getStateData, munimAddCustomEventListener, getCityData, handleFocusTab, planExpireCheck, getMfgCompanyList, isIosDevice, checkDesktopApp, getOS, setCookie, isCreateMode, isEditMode, isViewMode, isDefaultViewMode, isDefaultEditMode, isSalesMode, isPurchaseMode, isRouterMode }
